import memonomics from "../assets/images/memonomics.png";


const Tokenomics = () => {

    return ( 
        <>
            <div className="tokenomics">
                <div className="wrapper">
                    <div className="tokenomics-container">
                        <img src={memonomics} alt="" />
                        <div className="tokenomics-container-content">
                            <ul className="tokenomics-content">
                                <li><p>TOTAL SUPPLY: 1,000,000,000 $TRUMP</p></li>
                                <li><p>LAUNCH THROUGH PUMP.FUN</p></li>
                                <li><p>FAIR TOKENS DISTRIBUTION</p></li>
                                <li><p>LP BURNED</p></li>
                                <li><p>NO TEAM TOKENS</p></li>
                                <li><p>NO TAXES</p></li>
                                <li><p>CONTRACT REVOKED/UNMINTABLE/IMMUTABLE</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    );
}
 
export default Tokenomics;