import mem_two from "../assets/images/trump_one.svg";
import { useClipboard } from 'use-clipboard-copy';
import copy from "../assets/images/copy.svg";

const Preheader = () => {


    return ( 
        <>
            <div className="tokenomics">
                <div className="wrapper">

                <div className="content-cont-dog">
                            <div className="content-dog">
                                <p className="content"> 
                                <span>Trump</span> is a community movement <br />
                                <br />
                                With a community-driven ethos and wild <br />
                                enthusiasm, <span>Trump Verse</span> is set <br />
                                to revolutionize the crypto sphere. <br />
                                Get ready to ride the wave of excitement <br />
                                and watch your investments soar <br />
                                to new heights! <br />
                                <br />
                                <span>The ticker is $TRUMP</span> Located on <span>Solana</span> Blockchain <br /> 
                                <br /> 
                                Based and experienced team behind the project <br />
                                </p>  
                            </div>
                            <div className="content-img-dog">
                                <img src={mem_two} alt="" />
                            </div>
                        </div>
                </div>
            </div>
        </>
    );
}
 
export default Preheader;