import tw from "../assets/images/tw.png";
import tw1 from "../assets/images/tw1.png";
import tw3 from "../assets/images/tw3.png";
import jupiterlogo from "../assets/images/jupiter-ag-jup-logo.svg";
import pinksale from "../assets/images/ic-pinksale.61500ae2.svg";
import dexview from "../assets/images/dexview-cat-1.svg";

const PreFooter = () => {
    return ( 
        <>  
            <div className="prefooter-conteiner">
                <div className="wrapper">
                    <div className="prefooter-content-container">
                        <div className="prefooter">
                            <p className="prefooter">                             
                                $TRUMP is a meme coin with no intrinsic value or expectation of financial return. <br />
                                The coin is completely useless and for entertainment purposes only.
                            </p> 
                            <div className="social_link">
                                <a href="https://www.dexview.com/"><img src={dexview} alt="" /></a>
                                <a href="https://www.pinksale.finance/"><img src={pinksale} alt="" /></a>
                                <a href="https://www.digifinex.com/ru-ru"><img src={tw1} alt="" /></a>
                                <a href="https://raydium.io/"><img src={tw} alt="" /></a>
                                <a href="https://jup.ag/"><img src={jupiterlogo} alt="" /></a>
                                <a href="https://coinmarketcap.com/"><img src={tw3} alt="" /></a>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    );
}
 
export default PreFooter;