import trump from "../assets/images/trump.svg";
import memos_text from "../assets/images/trump_title.svg";
import telegram from "../assets/images/telegram.svg";
import x from "../assets/images/x.svg";
import tool from "../assets/images/tool.svg";
import buy from "../assets/images/buy.svg";
import noise from "../assets/images/noise.png";
import { useClipboard } from 'use-clipboard-copy';
import copy from "../assets/images/copy.svg";


const Header = () => {

    const clipboard = useClipboard({
        onSuccess() {
          console.log('Text was copied successfully!')
        },
        onError() {
          console.log('Failed to copy text!')
        }
      });

    return ( 
        <>
            <header className="header">
                <div className="wrapper">
                    <div className="header-container">
                        <div className="header-container-content">
                            <div className="dog">
                                <div className='twenty-conteiner'>
                                    <img className='dog-right' src={trump} />
                               {/*     <div className="copy">
                                        <h2>CA:</h2>
                                        <div className="copylabel"><input type="text" ref={clipboard.target} value="trumpEJ72GKhpgKkrfLtWCf6yZT2dPY781f8sy7uPTW"></input><button className="copybutton" onClick={clipboard.copy}><img className="copyicon" src={copy} alt="" /></button></div>
                                    </div>*/}
                                </div>
                            </div>
                            <div className="wallet-conteiner">
                                <div className="wallet">
                                    <img className='dog-left' src={memos_text} alt="" />
                                    <h2>
                                        <span>$TRUMP</span> Metaverse unites degens <br />
                                        from all over the world to fight <br />
                                        and win together! <br />
                                    </h2>
                                    <h3>
                                        Join the <span>$TRUMP</span> army By creating <br /> 
                                        and share Trump-style Hair pics <br />
                                    </h3>
                                    <div className="button-conteiner">
                                        <a href="http://hair.trumpverse.xyz/" className="button" ><img src={tool} alt="" /></a>
                                        <a href="" className="button" ><img src={buy} alt="" /></a>
                                    </div>
                                    <div className="social-conteiner">
                                        <h4>Powered by Solana</h4>
                                        <div className="social">
                                            <ul>
                                                <li><a href="https://t.me/TrumpVerseCoin"><img src={telegram} alt="" /></a></li>
                                                <li className="social-last-item"><a href="https://x.com/TrumpVerseCoin"><img src={x} alt="" /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="dogwifcash">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </header>
        </>
    );
}
 
export default Header;