import mempmap from "../assets/images/memomap.png";

const Roadmap = () => {
    return ( 
        <>
            <div  className="roadmap">
                <div className="wrapper">
                    <div className="roadmap-container">
                        <div className="roadmap-container-content">
                            <h2>ROADMAP</h2>
                            <ul className="roadmap-content">
                                <li className="roadmap-content-item-one">
                                    <span>Phase 1</span>
                                    <p>
                                        (Presale): Memes, <br /> Good vibes, Preparing <br />for Takeoff
                                    </p>
                                </li>
                                <li className="roadmap-content-item-two">
                                    <span>Phase 2</span>
                                    <p>
                                        (Public, DEX): <br /> Swap, Vibe,<br /> HODL, Repeat.
                                    </p>
                                </li>
                                <li className="roadmap-content-item-three">
                                    <span>Phase 3</span>
                                    <p>
                                        CEX listing on <br /> major exchanges
                                    </p>
                                </li>
                                <li className="roadmap-content-item-fore">
                                    <span>Phase 4</span>
                                    <p>
                                        NFT collection (free <br /> for $TRUMP <br /> holders), Mini-Game
                                    </p>
                                </li>
                                <li className="roadmap-content-item-five">
                                    <span>Phase 5</span>
                                    <p>
                                        Expanding <br /> $TRUMP <br /> Ecosystem
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <img src={mempmap} alt="" />
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default Roadmap;